import { useRef, useEffect, useState } from 'react';
import { Form } from 'formik';
import { useRouter } from 'next/router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Spinner } from '@maker-ui/loaders';

import { CollectionPicker } from '@/components/layout/CollectionPicker';
import { Header } from '@/components/layout/Header';
import { Footer } from '@/components/layout/Footer';
import { AppTitle } from '@/components/layout/AppTitle';
import { Carousel } from '@/components/layout/Carousel';
import { Totalizer } from '@/components/layout/Totalizer';
import { MaintenanceAlert } from '@/components/alerts/MaintenanceAlert';
import { ProductForm } from '@/components/form/ProductForm';
import { FulFillmentForm } from '@/components/form/FulfillmentForm';
import { OrderSummary } from '@/components/checkout/OrderSummary';
import { Stepper } from '@/components/form/Stepper';
import { ShipOptions } from '@/components/form/ShipOptions';
import { PaymentOptions } from '@/components/checkout/PaymentOptions';

import { useOrder, useShipment } from '@/context';
import { useEvent, useBeforeUnload, type EventType } from '@/hooks';
import { cn } from '@/utils/helper';
import styles from '@/styles/Home.module.scss';
import { CountdownBanner } from '@/components/alerts/CountdownBanner';

function renderStepContent(step: number, nextStep: () => void) {
  switch (step) {
    case 1:
      return <ProductForm nextStep={nextStep} />;
    case 2:
      return <FulFillmentForm nextStep={nextStep} />;
    case 3:
    case 4:
    case 5:
      return <OrderSummary />;
    default:
      return <div>Error with this form step. Please reload the page</div>;
  }
}

export default function HomePage() {
  const pageRef = useRef(null);
  const { createEvent } = useEvent();
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const { order, setProductType } = useOrder();
  const { shipping } = useShipment();
  const [currentStep, setCurrentStep] = useState(1);
  const [initialRender, setInitialRender] = useState(true);
  const router = useRouter();

  useBeforeUnload(() => {
    createEvent('Cart Abandoned');
  });

  const maintenance_mode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE;

  function nextStep(event?: EventType) {
    if (event) {
      createEvent(event);
    }
    setCurrentStep(currentStep + 1);
  }

  useEffect(() => {
    if (Object.keys(order.inventory).length) {
      setInventoryLoading(false);
    }
  }, [order.inventory]);

  useEffect(() => {
    // Check if 'product' query parameter exists
    if ('product' in router.query) {
      if (
        router.query.product === 'hempcrete' ||
        router.query.product === 'hempwool'
      ) {
        setProductType(router.query.product);
      }
    }
    setInitialRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  if (maintenance_mode) {
    return <MaintenanceAlert />;
  }

  if (!order.productType && !initialRender) {
    return <CollectionPicker setProductType={setProductType} />;
  }

  if (order.productType) {
    return (
      <main>
        {/* <Banner /> */}
        <CountdownBanner
          // hideCountdown
          targetDate="2025-03-09T15:59:00-08:00"
          leftText={`We're Celebrating USDA Bio-Preferred Day. Gift with purchase 3/6 - 3/9.`}
          rightText="Limited edition hat, workman gloves and utility marker - Free!"
        />
        <div className={styles.root}>
          <div className={styles.col}>
            <Header currentStep={currentStep} setStep={setCurrentStep} />
            <div className={cn(styles.media, 'flex flex-col')}>
              {currentStep === 2 &&
              !shipping.fetchState.showCarousel &&
              order.fulfillmentType === 'Delivery' ? (
                <div className={styles.content}>
                  <ShipOptions />
                </div>
              ) : currentStep === 3 ? (
                <div className={styles.content}>
                  <PaymentOptions />
                </div>
              ) : (
                <Carousel step={currentStep} />
              )}
              <Totalizer step={currentStep} />
            </div>
          </div>
          <div id="col-form" className={cn(styles.col, styles.col_form)}>
            <AppTitle product={order.productType} currentStep={currentStep} />
            <div
              className={cn(
                'form-content',
                currentStep !== 1 ? 'hide-title' : undefined
              )}>
              <Stepper currentStep={currentStep} totalSteps={3} />
              {inventoryLoading ? (
                <div
                  className={cn(
                    styles.loader,
                    'flex align-center justify-center flex-col'
                  )}>
                  Loading Inventory
                  <Spinner
                    className="spinner"
                    type="bars"
                    colors={{
                      primary: '#9CCD32',
                      secondary: '#bedf78',
                      third: '#a9b199',
                      fourth: '#797d71',
                    }}
                  />
                </div>
              ) : (
                <Form id="order-form">
                  <div className={styles.form_wrapper}>
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        key={currentStep}
                        classNames="fade"
                        timeout={300}
                        nodeRef={pageRef}>
                        <div ref={pageRef}>
                          {renderStepContent(currentStep, nextStep)}
                        </div>
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
        <Footer />
        {/* <Popup /> */}
      </main>
    );
  }
}
