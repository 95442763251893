import React from 'react';
import { useCountdown } from '@/hooks';
import styles from './CountdownBanner.module.scss';
import { cn } from '@/utils/helper';

interface CountdownBannerProps {
  /** The target date to count down to (in ISO string format) */
  targetDate: string;
  /** The text to display to the left of the countdown */
  leftText?: string;
  /** The text to display to the right of the countdown */
  rightText?: string;
  /** Whether to hide the countdown */
  hideCountdown?: boolean;
}

/** Pads a number with a leading zero if it is less than 10 */
const zeroPad = (num: number) => String(num).padStart(2, '0');

export const CountdownBanner: React.FC<CountdownBannerProps> = ({
  targetDate,
  leftText,
  rightText,
  hideCountdown = false,
}) => {
  const endDate = new Date(targetDate);

  const countdown = useCountdown(endDate);

  if (countdown.expired) {
    return null;
  }

  return (
    <div className={styles.banner}>
      {leftText && <div className={styles.text}>{leftText}</div>}
      {!hideCountdown && (
        <div className={styles.countdown}>
          <div className={styles.timeSection}>
            <div className={styles.timeValue}>{zeroPad(countdown.days)}</div>
            <div className={styles.timeLabel}>Days</div>
          </div>
          <div className={styles.timeSection}>
            <div className={styles.timeValue}>{zeroPad(countdown.hours)}</div>
            <div className={styles.timeLabel}>Hours</div>
          </div>
          <div className={styles.timeSection}>
            <div className={styles.timeValue}>{zeroPad(countdown.minutes)}</div>
            <div className={styles.timeLabel}>Minutes</div>
          </div>
          <div className={styles.timeSection}>
            <div className={styles.timeValue}>{zeroPad(countdown.seconds)}</div>
            <div className={styles.timeLabel}>Seconds</div>
          </div>
        </div>
      )}
      {rightText && (
        <div className={cn(styles.text, styles.rightText)}>{rightText}</div>
      )}
    </div>
  );
};
